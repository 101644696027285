<template>
  <section :class="theme === 'dark' ? 'text-white' : 'text-black'">
    <header class="h3 mb-4">Contest: {{ contest.title[$i18n.locale] || contest.name || contest.reference }}</header>
    <transition-group name="fade">
      <ResultProgress :progress="resultOrchestrator.status" v-if="resultOrchestrator.status !== 'finished'" />
      <div v-if="resultOrchestrator.status === 'finished'"
        :style="`${!isInstantRunoff && 'gap: 1.25rem;display: grid;'}`">
        <section :class="`${isInstantRunoff && 'mb-3'}`">
          <header class="h4 w-100">{{ $t('js.result.header') }}</header>
          <Result :result="resultOrchestrator.result" :contest="contest" always-show-result :theme="theme"
            :theme-color="election.theme.color" />
        </section>
      </div>
    </transition-group>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Result from "@/components/frontend/shared/Result.vue";
import { mapState } from "pinia";
import ResultProgress from "@/components/frontend/shared/ResultProgress.vue";
import { useSharedStore } from "@/entrypoints/stores/shared";
import type {
  PropType,
  ConferenceContest,
  Theme,
  ConferenceVotingRoundOrchestrator
} from "@/types";

export default defineComponent({
  name: "VotingRoundResult",
  components: { ResultProgress, Result },
  props: {
    resultOrchestrator: {
      type: Object as PropType<ConferenceVotingRoundOrchestrator>,
      required: true,
    },
    contest: {
      type: Object as PropType<ConferenceContest>,
      required: true,
    },
    theme: {
      type: String as PropType<Theme>,
      default: "dark",
    },
  },
  computed: {
    ...mapState(useSharedStore, ["election"]),
    result() {
      return this.resultOrchestrator.result;
    },
    isInstantRunoff() {
      return ["instant_runoff", "multi_seat_instant_runoff"].includes(this.contest.resultMethod);
    },
  },
});
</script>
